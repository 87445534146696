import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { B3gisLazyLoadComponent } from './b3gis-lazy-load/b3gis-lazy-load.component';
import { b3dashboardLazyLoadComponent } from './b3dashboard-lazy-load/b3dashboard-lazy-load.component';
import { B3chatLazyLoadComponent } from './b3chat-lazy-load/b3chat-lazy-load.component';
import { ExampleWrapperComponent } from './example-wrapper/example-wrapper.component';
import { getExampleCodeString } from './example-util';

import { regionMapExample } from 'src/app/examples-code/region-map-example.js';
import { districtMapExample } from 'src/app/examples-code/district-map-example.js';
import { placementMapExample } from 'src/app/examples-code/placement-map-example.js';
import { rpnChatExample } from 'src/app/examples-code/rpn-chat-example.js';
import { platformChatExample } from 'src/app/examples-code/platform-chat-example.js';
import { platformSideChatExample } from 'src/app/examples-code/platform-side-chat-example.js';

import { MainMenuComponent } from './main-menu/main-menu.component';

const routes: Routes = [
  { path: '', component: MainMenuComponent },
  { path: 'gis', component: B3gisLazyLoadComponent },
  { path: 'dashboard', component: b3dashboardLazyLoadComponent },
  { path: 'chat', component: B3chatLazyLoadComponent },
  { path: 'chat-platform-empty', component: B3chatLazyLoadComponent },
  { path: 'side-chat-platform-empty', component: B3chatLazyLoadComponent },
  { path: 'region-map-example', component: ExampleWrapperComponent, data: { exampleCode: getExampleCodeString(regionMapExample) } },
  { path: 'district-map-example', component: ExampleWrapperComponent, data: { exampleCode: getExampleCodeString(districtMapExample) } },
  { path: 'placement-map-example', component: ExampleWrapperComponent, data: { exampleCode: getExampleCodeString(placementMapExample) } },
  {
    path: 'chat-rpn',
    component: ExampleWrapperComponent,
    data: {
      exampleCode: getExampleCodeString(rpnChatExample),
      libType: 'chat'
    }
  },
  {
    path: 'chat-platform',
    component: ExampleWrapperComponent,
    data: {
      exampleCode: getExampleCodeString(platformChatExample),
      libType: 'chat'
    }
  },
  {
    path: 'side-chat-platform',
    component: ExampleWrapperComponent,
    data: {
      exampleCode: getExampleCodeString(platformSideChatExample),
      libType: 'chat'
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
