import { Component } from '@angular/core';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent {
  menuItems = [
    {
      name: 'ГИС - пустая страница для тестирования через консоль',
      href: '/gis'
    },
    {
      name: 'Информационная панель - пустая страница для тестирования через консоль',
      href: '/dashboard'
    },
    {
      name: 'Чат РПН - пустая страница для тестирования через консоль',
      href: '/chat'
    },
    {
      name: 'Чат Платформы - пустая страница для тестирования через консоль',
      href: '/chat-platform-empty',
      queryParams: {
        lib_url: 'https://205129.selcdn.ru/b3-gis/lib-chat-platform/',
        class_name: 'Chat'
      }
    },
    {
      name: 'Чат Платформы боковой - пустая страница для тестирования через консоль',
      href: '/side-chat-platform-empty',
      queryParams: {
        lib_url: 'https://205129.selcdn.ru/b3-gis/lib-chat-platform/',
        class_name: 'SideChat'
      }
    },
    {
      name: 'Карта региона - демо',
      href: '/region-map-example'
    },
    {
      name: 'Карта района - демо',
      href: '/district-map-example'
    },
    {
      name: 'Карта демо местоположения',
      href: '/placement-map-example'
    },
    {
      name: 'Чат РПН - демо',
      href: '/chat-rpn'
    },
    {
      name: 'Чат "Платформа" - демо',
      href: '/chat-platform',
      queryParams: {
        lib_url: 'https://205129.selcdn.ru/b3-gis/lib-chat-platform/'
      }
    },
    {
      name: 'Чат "Платформа боковой" - демо',
      href: '/side-chat-platform',
      queryParams: {
        lib_url: 'https://205129.selcdn.ru/b3-gis/lib-chat-platform/'
      }
    }
  ];
}
